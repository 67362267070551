import React from 'react';
import { NavLink } from 'react-router-dom';

import './Logo.css';

class Logo extends React.Component {
  render() {
    return (
      <div>
        <div className="menu-bar" />
        <div className="logo">
          <NavLink className="logo-link" activeClassName="logo-link-active" exact to="/">
            <h1>Diego G.M.</h1>
          </NavLink>
        </div>
      </div>
    )
  }
}

export default Logo;