import React from 'react';

import './Contact.css';

class Email extends React.Component {
   onClick() {
      var win = window.open("mailto:diego.garcia.medina@outlook.com", '_blank');
      win.focus();
   }
   render () {
      return (
         <div className="contact" onClick={this.onClick}>
            <svg className="contact-icon" xmlns="http://www.w3.org/2000/svg" width="10vw" height="10vh" viewBox="0 0 24 24"><path d="M12 2.02c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 12.55l-5.992-4.57h11.983l-5.991 4.57zm0 1.288l-6-4.629v6.771h12v-6.771l-6 4.629z"/></svg>
            <div className="contact-content">
               <p className="contact-title">Email</p>
               <p className="contact-text">diego.garcia.medina@outlook.com</p>
            </div>
         </div>
      )
   }
}

class LinkedIn extends React.Component {
   onClick() {
      var win = window.open("https://www.linkedin.com/in/diego-garcia-medina/", '_blank');
      win.focus();
    }
   render () {
      return (
         <div className="contact" onClick={this.onClick}>
            <svg className="contact-icon" xmlns="http://www.w3.org/2000/svg" width="10vw" height="10vh" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z"/></svg>
            <div className="contact-content">
               <p className="contact-title">LinkedIn</p>
               <p className="contact-text">www.linkedin.com/in/diego-garcia-medina/</p>
            </div>
         </div>
      )
   }
}

class Twitter extends React.Component {
   onClick() {
      var win = window.open("https://twitter.com/madicap5", '_blank');
      win.focus();
    }
   render () {
      return (
         <div className="contact" onClick={this.onClick}>
            <svg className="contact-icon" width="10vw" height="10vh" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"/></svg>
            <div className="contact-content">
               <p className="contact-title">Twitter</p>
               <p className="contact-text">@madicap5</p>
            </div>
         </div>
      )
   }
}

const Contacts = () => {
   return (
      <div>
         <div className="title-projects">
            <h1 className="title-content">Contact</h1>
         </div>
         <div className="contacts">
            <Email />
            <LinkedIn />
            <Twitter />
         </div>
         <p className="EOF">&lt; Created by Diego G.M. (2020) /&gt;</p>
      </div>
   );
}

export default Contacts;