import React from 'react'
import * as THREE from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import './3DRobot.css'

class My3DRobot extends React.Component {

  componentDidMount() {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer({ antialias: true })

    this.clock = new THREE.Clock();

    this.robot_loaded = false

    var loader = new GLTFLoader();
    loader.load( '3dmodels/robot.glb', ( gltf ) => {
      this.robot = gltf.scene
      this.robot.position.y = -2
      console.log(gltf)
      scene.add( gltf.scene );
      this.mixer = new THREE.AnimationMixer(this.robot)
      var clip = gltf.animations[0]
      console.log(clip)
      var action = this.mixer.clipAction( clip );
      action.play()
      this.robot_loaded = true
    }, undefined, function ( error ) {
      console.error( error );
    } );

    var light = new THREE.PointLight(0xffffff)
    light.position.set(0, 500, 500)
    scene.add(light)

    light = new THREE.AmbientLight(0xffffff)
    // light.position.set(-500, 500, -500)
    scene.add(light)

    renderer.setClearColor('#000000')
    renderer.setSize(width, height)

    this.scene = scene
    this.camera = camera
    this.renderer = renderer

    // Positions & Rotations
    this.camera.position.set(0, 2, 5)
    this.camera.up.set(0, 1, 0)

    this.controls = new OrbitControls(this.camera, renderer.domElement)
    this.controls.enableZoom = false
    // this.controls.autoRotate = false
    // this.controls.enableRotate = false
    this.controls.screenSpacePanning = false
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE
    }
    this.controls.enableDamping = true

    window.addEventListener('resize', this.handleResize)

    this.mount.appendChild(this.renderer.domElement)
    this.start()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  handleResize = () => {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight
    this.renderer.setSize(width, height)
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  animate = (t) => {
    if (this.robot_loaded) {
      this.robot.rotation.y += Math.PI/1000
    }
    var dt = this.clock.getDelta();
    if ( this.mixer ) this.mixer.update( dt );
    this.controls.update()
    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  render() {
    return (
      <div
        className="vis-robot"
        ref={mount => {
          this.mount = mount
        }}
      />
    )
  }
}

export default My3DRobot