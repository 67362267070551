import React from 'react';
import './Projects.css';

class ProjectThumbnail extends React.Component {
   render() {
      return (
         <div className="project" ref={this.props.myRef} onClick={this.props.select}>
            <p className="project-title">{this.props.projectTitle}</p>
            {
               this.props.projectThumbnailImageSrc ?
               <div className="project-image-container">
                  <img className="project-image" src={this.props.projectThumbnailImageSrc} alt=""/>
               </div>
               : 
               (this.props.projectImageSrc ?
                  <div className="project-image-container">
                     <img className="project-image" src={this.props.projectImageSrc} alt=""/>
                  </div>
                  : null
               )
            }
            <p className="project-content">{this.props.projectSubtitle}</p>
         </div>
      )
   }
}

class ProjectExpanded extends React.Component {
   render() {
      return (
         <div className="project-expanded" ref={this.props.myRef} onClick={this.props.select}>
            <p className="project-expanded-title">{this.props.projectTitle}</p>
            <div className="project-expanded-content">
               {
                  this.props.projectVideoSrc ?
                     <video
                        controls
                        muted
                        ref={c => {this.myvideo = c}}
                        className="project-expanded-video"
                        // onClick={this.onVideoClick}
                        width="100%"
                        src={this.props.projectVideoSrc}
                     />
                     : 
                     (
                        this.props.projectImageSrc ?
                        <img className="project-expanded-image" src={this.props.projectImageSrc} alt=""/>
                        : null
                     )
               }
               <div className="project-expanded-content-right">
                  <p className="project-expanded-subtitle">{this.props.projectSubtitle}</p>
                  <p className="project-expanded-text">{this.props.projectContent}</p>
                  <p className="project-expanded-text">Want to know more?</p>
                  <div className="prject-button-container">
                     <a className="project-button" href={this.props.projectButtonPath} target="_blank" rel="noopener noreferrer">
                        {this.props.projectButtonDescription}
                     </a>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

class Project extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         selected: false,
         project_name: ''
      }
      this.select = this.select.bind(this)
   }
   select(event) {
      this.setState({
         selected : !this.state.selected,
      })
   }
   componentDidUpdate() {
      this.myRef.scrollIntoView({behaviour: "smooth", block: "center"})
   }
   render() {
      return !this.state.selected ?
         <ProjectThumbnail
            myRef={c => {this.myRef = c}}
            select={this.select}
            projectTitle={this.props.projectTitle}
            projectSubtitle={this.props.projectSubtitle}
            projectVideoSrc={this.props.projectVideoSrc}
            projectImageSrc={this.props.projectImageSrc}
            projectThumbnailImageSrc={this.props.projectThumbnailImageSrc}
         />
         : <ProjectExpanded
            myRef={c => {this.myRef = c}}
            select={this.select}
            projectTitle={this.props.projectTitle}
            projectSubtitle={this.props.projectSubtitle}
            projectVideoSrc={this.props.projectVideoSrc}
            projectImageSrc={this.props.projectImageSrc}
            projectContent={this.props.projectContent}
            projectButtonDescription={this.props.projectButtonDescription}
            projectButtonPath={this.props.projectButtonPath}
         />
   }
}

const Projects = () => {
   return (
      <div>
         <div className="title-projects">
            <h1 className="title-content">Projects</h1>
         </div>
         <div className="content-projects">
            <Project
               projectTitle=     "Master's Project" 
               projectSubtitle=  "Reinforcement Learning for General-Purpose Robot Programming"
               projectImageSrc=  "files/Cambridge.jpg"
               projectVideoSrc=  "files/Masters_Project.mp4"
               projectContent=   {`Reinforcement Learning (RL) is a subset of 
                                 Machine Learning that is characterised by 
                                 algorithms that enable an artificial agent to 
                                 learn how to take actions in non-deterministic 
                                 environments, in order to maximise the 
                                 long-term sum of rewards it receives. Without 
                                 any prior information of said environment, the 
                                 agent can devise an optimum strategy after 
                                 exploring, through trial-and-error, the outcome 
                                 of chosen actions from different states. This 
                                 way of learning makes RL a very powerful tool 
                                 that can be used to solve complex control 
                                 problems through the careful design of the 
                                 reward function. Moreover, when RL incorporates 
                                 techniques from Deep Learning, namely neural 
                                 networks, non-linear control problems with high 
                                 dimensionality can also be solved. This makes 
                                 RL a promising technique to program robots to 
                                 behave in sophisticated ways.`}
               projectButtonDescription= "Download my Thesis"
               projectButtonPath= "/files/Garcia_Medina_D_Masters_Project.pdf"
            />
            <Project
               projectTitle= "Stock Predictor"
               projectSubtitle= "Binary Options Pricing using Long Short-Term Memory and Monte Carlo Black Scholes' Models"
               projectContent= {`In finance, an option is a contract which gives
                                 the buyer (the owner or holder of the option)
                                 the right, but not the obligation, to buy or
                                 sell an underlying asset or instrument at a
                                 specified strike price prior to or on a
                                 specified date, depending on the form of the
                                 option. The strike price may be set by
                                 reference to the spot price (market price) of
                                 the underlying security or commodity on the day
                                 an option is taken out, or it may be fixed at
                                 a discount or at a premium. The seller has the
                                 corresponding obligation to fulfill the
                                 transaction – to sell or buy – if the buyer
                                 (owner) "exercises" the option. An option
                                 that conveys to the owner the right to buy at
                                 a specific price is referred to as a call;
                                 an option that conveys the right of the owner
                                 to sell at a specific price is referred to as a
                                 put.`}
               projectImageSrc= "files/stock_predictor.jpg"
               projectButtonPath= "https://github.com/DGarciaMedina/stock_predictor#readme"
               projectButtonDescription= "Check out my GitHub page"
            />
            <Project
               projectTitle= "Sudoku Solver"
               projectSubtitle= "Tree-based Search Algorithm Based on Backtracking with Interactive GUI"
               projectImageSrc= "files/sudoku_screenshot.png"
               projectThumbnailImageSrc= "files/sudoku.jpg"
               projectButtonPath= "https://github.com/DGarciaMedina/Sudoku-Solver#readme"
               projectButtonDescription= "Check out my GitHub page"
            />
         </div>
         <p className="EOF">&lt; Created by Diego G.M. (2020) /&gt;</p>
      </div>
   );
}

export default Projects;