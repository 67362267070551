import React from 'react'
import * as THREE from 'three'

import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'

import './3DGraph.css'

const POINT_RADIUS = 0.01

// const NUM_POINTS = 5
const NUM_LINES = 18

const LINE_SEPARATION = 0.15

class My3DGraph extends React.Component {

  componentDidMount() {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(70, width / height, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer({ antialias: true })

    this.lines = []
    const line_material = new THREE.LineBasicMaterial({color: 0xA9A9A9})
    
    for (var i=0; i<NUM_LINES; i++) {
      var line_points = []
      const x = LINE_SEPARATION * i
      line_points.push( new THREE.Vector3(x, 0, 0) )
      line_points.push( new THREE.Vector3(x, 2, 0) )
      const line_geometry = new THREE.BufferGeometry().setFromPoints(line_points)
      var line = new THREE.Line(line_geometry, line_material)
      scene.add(line)
      this.lines.push(line)
    }
    
    this.data_fetched = false

    renderer.setClearColor('#000000')
    renderer.setSize(width, height)

    this.scene = scene
    this.camera = camera
    this.renderer = renderer

    this.controls = new OrbitControls(this.camera, renderer.domElement)
    this.controls.enableZoom = false
    // this.controls.autoRotate = false
    this.controls.enableRotate = false
    this.controls.screenSpacePanning = false
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN
    }
    this.controls.enableDamping = true

    this.controls.target.set(1.25, 1, 0)
    // Positions & Rotations
    this.camera.position.set(1.25, 0.9, 1.5)
    this.camera.up.set(0, 0, 1)
    // this.camera.lookAt(2, 1, 0)

    var minPan = new THREE.Vector3( 0.5, -2, -2 );
    var maxPan = new THREE.Vector3( 2.25, 2, 2 );
    var _v = new THREE.Vector3();
    
    this.controls.addEventListener("change", () => {
        _v.copy(this.controls.target);
        this.controls.target.clamp(minPan, maxPan);
        _v.sub(this.controls.target);
        this.camera.position.sub(_v);
    })

    window.addEventListener('resize', this.handleResize)

    this.mount.appendChild(this.renderer.domElement)
    this.start()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  async getExchangeRates() {
    const response = await fetch("https://api.exchangeratesapi.io/history?start_at=2000-03-15&end_at=2020-08-14&symbols=GBP,USD")
    return response.json()
  }

  async getStockPrices(stock_name="TSLA") {
    const response = await fetch(`https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${stock_name}&apikey=WTWCIYUXB7FXOOPD`)
    return response.json()
  }

  handleResize = () => {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight
    this.renderer.setSize(width, height)
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  async add_stock_label(stock_name="TSLA", stock_color=0xFF6600, height) {
    var loader = new THREE.FontLoader();
    loader.load('https://threejs.org/examples/fonts/helvetiker_bold.typeface.json',
    (font) => {
      var label_geometry = new THREE.TextGeometry(stock_name, {
        font: font,
        size: 0.1,
        height: 0.01,
        curveSegments: 12
      });
      var label_material = new THREE.MeshBasicMaterial({ color: stock_color });
      const label = new THREE.Mesh(label_geometry, label_material);
      label.position.set(2.6, height, 0)
      this.scene.add(label)
    })
  }

  async plot_stock(stock) {
    const results = await this.getStockPrices(stock[0])
    console.log(results)
    const point_geometry = new THREE.SphereGeometry(POINT_RADIUS, 8, 8)
    const dates = Object.keys(results["Time Series (Daily)"])
    const NUM_POINTS = dates.length
    var point_material = new THREE.MeshBasicMaterial({ color: stock[1] })
    for (var i = 0; i < NUM_POINTS; i += 1) {
      const point = new THREE.Mesh(point_geometry, point_material)
      point.position.y = results["Time Series (Daily)"][dates[NUM_POINTS - i - 1]]["1. open"] / results["Time Series (Daily)"][dates[NUM_POINTS-1]]["1. open"] / 2
      point.position.x = 2.5 * i / NUM_POINTS
      this.scene.add(point)
      this.points[stock][i] = point
    }
    this.add_stock_label(stock[0], stock[1], this.points[stock][NUM_POINTS-1].position.y)
  }

  animate = (t) => {
    this.controls.enabled = false;
    this.camera.position.y = 0.9
    this.controls.target.y = 1
    this.controls.enabled = true;

    // this.camera.setFocalLength(this.mount.width/100)
    // // console.log(this.camera.getFocalLength())

    if (!this.data_fetched) {
      this.points = {}
      const stocks = [["TSLA", 0xFF6600],["AAPL", 0xFFFF00],["GOOGL", 0x00FF00]]
      // const stocks = [["TSLA", 0xFF6600]]
      stocks.forEach( stock => {
        this.points[stock] = []
        this.plot_stock(stock).then(() => {
          this.data_fetched = true
          this.controls.update()
          this.renderScene()
          this.frameId = window.requestAnimationFrame(this.animate)
        })
      })
    }
    else {
      this.controls.update()
      this.renderScene()
      this.frameId = window.requestAnimationFrame(this.animate)
    }
  }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  render() {
    return (
      <div
        className="vis-graph"
        ref={mount => {
          this.mount = mount
        }}
      />
    )
  }
}

export default My3DGraph