import React from 'react';
import './Navigation.css';

import { NavLink } from 'react-router-dom';
import { HamburgerCollapse } from 'react-animated-burgers'

class Menu extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isActive: false,
         loaded: false
      }
      this.toggleButton = this.toggleButton.bind(this)
      this.onClick = this.onClick.bind(this)
   }
   toggleButton() {
      if (!this.state.loaded) {
         this.setState({
            loaded: true
         })
      }
      this.setState({
         isActive: !this.state.isActive
      })
   }
   onClick() {
      this.setState({
         isActive: !this.state.isActive
      })
   }
   render() {
      return (
         <div className="menu">
            <div className="menu-hamburger">
               <HamburgerCollapse isActive={this.state.isActive} toggleButton={this.toggleButton} barColor="white" buttonWidth={25} duration={0.8}/>
            </div>
            {this.state.loaded ? 
               <div className={this.state.isActive ? "full-screen-menu-active" : "full-screen-menu"}>
                  <NavLink exact to="/" className="nav-title-full-screen" activeClassName="nav-title-active-full-screen" onClick={this.onClick}>Home</NavLink>
                  <NavLink exact to="/projects" className="nav-title-full-screen" activeClassName="nav-title-active-full-screen" onClick={this.onClick}>Projects</NavLink>
                  <NavLink exact to="/contact" className="nav-title-full-screen" activeClassName="nav-title-active-full-screen" onClick={this.onClick}>Contact</NavLink>
               </div>
               : null
            }
         </div>
      )
   }
};

class Navigation extends React.Component {
   render () {
      return (
         <div>
            <Menu/>
         </div>
      )
   }
};

export default Navigation;