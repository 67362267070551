import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Navigation from './components/Navigation';
import Error from './components/Error';
import Contacts from './components/Contact';
import Logo from './components/Logo';

class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.path === this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={props => (<Component {...props} />)} />;
  }
}


function App() {
  return (
    <BrowserRouter className="AppParent">
      <div className="App">
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap" rel="stylesheet"/>
        <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap" rel="stylesheet" />
        <Navigation />
        {/* <ScrollingProgressBar /> */}
        <Switch>
          <ScrollToTopRoute path="/" component={AboutMe} exact/>
          <ScrollToTopRoute path="/projects" component={Projects}/>
          <ScrollToTopRoute path="/contact" component={Contacts}/>
          <ScrollToTopRoute component={Error}/>
        </Switch>
        <Logo />
      </div>
    </BrowserRouter>
  );
}

export default App;
