import React from 'react'
import * as THREE from 'three'

import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'

import './3DWave.css'

const POINT_RADIUS = 0.05

var WAVE_CENTRE_X = 0
var WAVE_CENTRE_Y = 0

const NUM_POINTS_X = 200
const SEPARATION_X = 0.5
const FREQ_X = Math.PI / 8
const SPEED_X = 0.001

const NUM_POINTS_Y = 70
const SEPARATION_Y = 0.5
const FREQ_Y = Math.PI / 6
const SPEED_Y = 0

const AMPLITUDE = 1

const INIT_CAMERA_POS_Y = -SEPARATION_Y*NUM_POINTS_Y*0.5
const INIT_CAMERA_POS_Z = 4


class My3DWave extends React.Component {

  componentDidMount() {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer({ antialias: true })

    var points = Array(NUM_POINTS_X)
    for (var i = 0; i < points.length; i++) { 
      points[i] = []; 
    } 
    
    // Geometries
    const point_geometry = new THREE.SphereGeometry(POINT_RADIUS, 8, 8)
    
    // Materials
    // const point_material = new THREE.MeshNormalMaterial()
    const point_material = new THREE.MeshBasicMaterial({color: "white"})

    // Positions & Rotations
    camera.position.z = INIT_CAMERA_POS_Z
    camera.position.y = INIT_CAMERA_POS_Y
    // camera.rotation.z = Math.PI 
    camera.rotation.x = Math.PI / 3

    var j, x, y
    for (i=0, x= -(NUM_POINTS_X/2)*SEPARATION_X + WAVE_CENTRE_X; x<(NUM_POINTS_X/2)*SEPARATION_X + WAVE_CENTRE_X; x+=SEPARATION_X, i++) {
      for (j=0, y= -(NUM_POINTS_Y/2)*SEPARATION_Y + WAVE_CENTRE_Y; y<(NUM_POINTS_Y/2)*SEPARATION_Y + WAVE_CENTRE_Y; y+=SEPARATION_Y, j++) {
        const point = new THREE.Mesh(point_geometry, point_material)
        point.position.x = x
        point.position.y = y
        scene.add(point)
        points[i][j] = point
      }
    }

    renderer.setClearColor('#000000')
    renderer.setSize(width, height)

    this.scene = scene
    this.camera = camera
    this.renderer = renderer
    this.points = points

    this.camera.up.set(0,0,1)

    this.controls = new OrbitControls(this.camera, renderer.domElement)
    this.controls.enableZoom = false
    this.controls.enableRotate = false
    this.controls.screenSpacePanning = false
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.PAN
    }
    this.controls.enableDamping = true

    window.addEventListener('resize', this.handleResize)

    this.mount.appendChild(this.renderer.domElement)
    this.start()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  handleResize = () => {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight
    this.renderer.setSize(width, height)
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  animate = (t) => {
    // const t = 0
    var i, j, x, y
    for (i=0, x= -(NUM_POINTS_X/2)*SEPARATION_X + WAVE_CENTRE_X; x<(NUM_POINTS_X/2)*SEPARATION_X + WAVE_CENTRE_X; x+=SEPARATION_X, i++) {
      for (j=0, y= -(NUM_POINTS_Y/2)*SEPARATION_Y + WAVE_CENTRE_Y; y<(NUM_POINTS_Y/2)*SEPARATION_Y + WAVE_CENTRE_Y; y+=SEPARATION_Y, j++) {
        try {
          this.points[i][j].position.x = x
          this.points[i][j].position.y = y 
          this.points[i][j].position.z = AMPLITUDE * Math.cos(FREQ_X*x - SPEED_X*t) * Math.cos(FREQ_Y*y - SPEED_Y*t)
        }
        catch (error) {
          console.log(error)
        }
      }
    }

    WAVE_CENTRE_Y = this.camera.position.y - INIT_CAMERA_POS_Y
    WAVE_CENTRE_X = this.camera.position.x

    this.controls.update()

    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  render() {
    return (
      <div
        className="vis"
        ref={mount => {
          this.mount = mount
        }}
      />
    )
  }
}

export default My3DWave