import React from 'react';

import './AboutMe.css';
import My3DWave from './3DWave'
import My3DGraph from './3DGraph'
import My3DGraduation from './3DGraduation'
import My3DRobot from './3DRobot'

class Intro extends React.Component {
   render () {
      return (
         <div>
            <div className="intro">
               <h1 className="intro-title">About Me</h1>
               <p className="intro-content">
                  My name is Diego Garcia Medina and I'm a Full-Stack Financial Software
                  Engineer at <b>Bloomberg LP</b> in London, U.K. My current team 
                  (MARS Front Office) is focused on Risk Analysis at the Portfolio
                  Level, and I have also interned with the Bloomberg Derivatives
                  Pricing Team. Furthermore, I have worked as a Machine Learning
                  Intern at <b>Sony</b>, where I worked on the Hawk-Eye Innovations 
                  Computer Vision VAR project for the 2018 Football World Cup.
               </p>
            </div>
            <My3DGraph />
         </div>
      )
   }
}

class Education extends React.Component {
   render () {
      return (
         <div>
            <div className="intro">
               <h1 className="intro-title">Education</h1>
               <p className="intro-content">
                  I graduated in June 2020 at the the <b>University of
                  Cambridge</b> with a BA & MEng in Computer and Information
                  Engineering. My First Class Master's Project was titled:  
                  &nbsp;<i>Reinforcement Learning for General-Purpose Robot Programming</i>.
               </p>
            </div>
            <My3DGraduation />
         </div>
      )
   }
}

class Interests extends React.Component {
   render () {
      return (
         <div>
            <div className="intro">
               <h1 className="intro-title">Interests</h1>
               <p className="intro-content">
                  I am passionate about software development, finance and 
                  applied mathematics. I am competent with several programming
                  languages, but C++/17 is my favourite. Machine Learning is also a
                  field that interests me, in particular NLP and RL...
                  Robotics can be fun too!
               </p>
            </div>
            <My3DRobot />
         </div>
      )
   }
}

class CV extends React.Component {
   render() {
      return (
         <div className="cv">
            <h1 className="cv-title">My Résumé</h1>
            <p className="cv-content">You can view the latest version of my Résumé here:</p>
            <a className="cv-button" href="/files/Garcia_Medina_D_Resume.pdf" target="_blank">
               Click me!
            </a>
         </div>
      )
   }
}

class AboutMe extends React.Component {

   render () {
      return (
         <div>
            <div className="title">
               <My3DWave className="scene"/>
               <h1 className="hello-world">Hello<br />World!</h1>
            </div>
            <div className="content">
               <Intro />
               <Education />
               <Interests />
               <CV />
            </div>
            <p className="EOF">&lt; Created by Diego G.M. (2020) /&gt;</p>
         </div>
      );
   }
};

export default AboutMe;